import React, {Component} from 'react'
import Wrapper from '../components/Wrapper';
import PageTitle from "../components/PageTitle/index";
import Blurb from "../components/Blurb/index";
import Letterbox from "../components/Letterbox/index";
import AboutUs from "../modules/AboutUs/index";
import Storytellers from "../components/Storytellers/index";
import { graphql } from 'gatsby';

class About extends Component {
    render() {

        const page = this.props.data.wordpressPage;
        const acfFields = this.props.data.wordpressPage.acf;

        let seoTitle = acfFields.meta_title;
        let seoDescription = acfFields.meta_description;
        if (seoTitle===''){
            seoTitle = page.title;
        }

        let path = this.props.data.wordpressPage.slug;
        if (path === 'home') path = '';
        // add a trailing slash
        if (path.substr(-1) !== '/' && path !== '') path += '/';

        return (
            <Wrapper
                title={seoTitle}
                description={seoDescription}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : null}
                path={`/${path}`}
            >
                <PageTitle
                    title={page.title}
                    image={page.acf.page_title_banner.localFile.childImageSharp.original.src}
                />
                <Blurb
                    content={page.acf.page_blub}
                />
                <Storytellers
                    images={page.acf.storyteller_images}
                />
                <AboutUs
                    header={page.acf.story_heading}
                    columns={page.acf.story_columns}
                />
                <Letterbox
                    header={page.acf.letterbox_heading}
                    ctaText={page.acf.letterbox_cta_text}
                    ctaLink={page.acf.letterbox_cta_link}
                />
            </Wrapper>
        )
    }
}

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      id
      slug
      link
      wordpress_id
      wordpress_parent
      title
      acf {
        page_title_banner {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }
        }
        page_blub
        storyteller_images {
          id
          localFile {
            childImageSharp {
              fluid(maxWidth: 315, maxHeight:400, quality: 90) {
                ... GatsbyImageSharpFluid
              }
            }
          }
        }
        story_heading
        story_columns {
          column_content
        }
        letterbox_heading
        letterbox_cta_text
        letterbox_cta_link
        meta_title
        meta_description
		og_title
        og_description
        og_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        twitter_title
        twitter_card_type
        twitter_description
        twitter_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }  
      }
    }
  }
`

export default About
