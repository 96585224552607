import React from 'react'
import './style.less';
import { Row, Col } from 'antd';

class AboutUs extends React.Component {

    render(){

        let colCount = 0;

        return (
            <Row className="about-us">
                <Col xs={{span: 24}}>
                    <h2 className="huge"><span>Story</span></h2>
                </Col>
                <Col xs={{span: 24}}>
                    <p className="lead">{this.props.header}</p>
                </Col>
                {this.props.columns && this.props.columns.map((column) => {
                    colCount++
                    return <Col xs={{span: 24}} md={{span: 8}} key={"about-col-count-"+colCount}>
                        <p>{column.column_content}</p>
                    </Col>
                })}
            </Row>
        );
    }
}

export default AboutUs